<template>
    <div class="topbar">
        <div class="items-center" :class="{'flex': !multiview}">
            <h3 class="text-lg font-bold mb-2 md:mb-0" :class="{'flex': multiview}">{{currentUserName}} ({{taskLength}})
                <a-button v-if="multiview" class="flex items-center ml-auto" size="small" @click="openTaskslist">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"/></svg>
                </a-button>
                <a-switch class="ml-2 md:hidden" size="small" :checked="canReorder" checked-children="Reorder Enabled" un-checked-children="Reorder Disabled" @change="reorderSwitchChanged"></a-switch>
            </h3>
            <div class="items-center gap-3 ml-auto hidden sm:hidden md:flex md:flex-wrap">
                <!--<a-switch size="small" :checked="isKanban" checked-children="Kanban" un-checked-children="Standard" @change="tasklistTypeChanged"></a-switch>-->
                <a-switch size="small" :checked="canReorder" checked-children="Reorder Enabled" un-checked-children="Reorder Disabled" @change="reorderSwitchChanged"></a-switch>

                <a-radio-group :value="tasklistType" button-style="solid" @change="tasklistTypeChanged" size="small">
                    <a-radio-button value="standard">Standard</a-radio-button>
                    <a-radio-button value="kanban">Kanban</a-radio-button>
                </a-radio-group>

                <a-button size="small" class="text-xs flex items-center" type="primary" :disabled="newTasksCount === 0" :danger="newTasksCount > 0" @click="updateTasks">
                    <template v-if="newTasksCount === 0">No New Tasks</template>
                    <template v-else-if="newTasksCount === 1"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"/></svg>{{newTasksCount}} new task!</template>
                    <template v-else-if="newTasksCount > 1"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"/></svg>{{newTasksCount}} new tasks!</template>
                </a-button>

                <a-button size="small" class="text-xs" type="primary" @click="removeCompleted" :disabled="!showRemoveCompleted">Remove Completed</a-button>
                <a-button size="small" class="text-xs" v-if="!multiview" @click="goToCompleted">View Completed</a-button>
                <a-button size="small" class="text-xs" v-if="!multiview" @click="goToDeleted">View Deleted</a-button>

                <a-select ref="taskNameSelect" style="width: 200px" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }" size="small" class="text-xs ml-auto" placeholder="Switch Task List" @change="swapTaskList" v-if="!multiview"></a-select>
            </div>
        </div>

        <div class="flex gap-1 md:hidden">
            <a-button size="small" class="text-xs flex items-center" type="ghost" :disabled="newTasksCount === 0" :danger="newTasksCount > 0" @click="updateTasks">
                <template v-if="newTasksCount === 0">No New Tasks</template>
                <template v-else-if="newTasksCount === 1"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"/></svg>{{newTasksCount}} new task!</template>
                <template v-else-if="newTasksCount > 1"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"/></svg>{{newTasksCount}} new tasks!</template>
            </a-button>


            <a-select ref="taskNameSelect" style="width: 100%" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }" size="small" class="text-xs ml-auto" placeholder="Switch Task List" @change="swapTaskList" v-if="!multiview"></a-select>

            <a-dropdown class="ml-auto md:hidden" :trigger="['click']" placement="bottomRight">
                <a-button size="small">
                    <EllipsisOutlined />
                </a-button>
                <template #overlay>
                    <a-menu>
                        <a-menu-item key="1" @click="removeCompleted">Remove Completed</a-menu-item>
                        <a-menu-item key="2" @click="goToCompleted">View Completed</a-menu-item>
                        <a-menu-item key="3" @click="goToDeleted">View Deleted</a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>

        <div class="my-2 flex items-center gap-3">
            <a-input size="medium" placeholder="Add new task..." class="rounded" v-model:value="newTaskText" @keydown.tab="addNewTask" @keyup.enter="addNewTask" :disabled="newTaskLoading">
                <template #addonAfter>
                    <a-button size="small" type="text" :disabled="newTaskLoading" @click="addNewTask" :loading="newTaskLoading">Add Task</a-button>
                </template>
            </a-input>
            <a-switch size="small" v-model:checked="newTaskPositionTop" checked-children="Top" un-checked-children="Bottom" v-if="!multiview" class="hidden sm:hidden md:block"/>
        </div>
    </div>
</template>

<script>
import { EllipsisOutlined } from '@ant-design/icons-vue';
import {useAuthStore} from "@/store/authStore";
export default {
    props: ['tasks', 'multiview', 'newTasksCount', 'currentUserID', 'currentUserName', 'canReorder', 'showRemoveCompleted', 'tasklistType'],
    emits: ['get-tasks', 'update-tasks', 'swap-task-list', 'tasklist-type-changed'],
    components: {
        EllipsisOutlined
    },
    data() {
        return {
            newTaskText: "",
            newTaskPositionTop: true,
            newTaskLoading: false,
            noCompletedTasks: true
        }
    },
    mounted() {},
    computed: {
        user() {
            return useAuthStore().user || null;
        },
        taskLength() {
            if(Array.isArray(this.tasks)) {
                return this.tasks.length
            } else {
                return this.tasks['testing'].length + this.tasks['in-progress'].length + this.tasks['todo'].length + this.tasks['complete'].length
            }
        }
        /*noCompletedTasks() {
            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                if (task.pending_completed || task.pendin_complete) {
                    return false
                }
                for (let j = 0; j < task.subtasks.length; j++) {
                    const subtask = task.subtasks[j];
                    if (subtask.pending_completed || subtask.pendin_complete) {
                        return false
                    }
                }
            }
            return true;
        }*/
    },
    methods: {
        goToCompleted() {
            this.$router.push({ name: 'CompletedTasks', params: { id: this.currentUserID } })
        },
        goToDeleted() {
            this.$router.push({ name: 'DeletedTasks', params: { id: this.currentUserID } })
        },
        removeCompleted() {
            const tasksToRemove = [];
            const subtasksToRemove = [];
            const jobpartsToRemove = [];
            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                if (task.pending_completed) {
                    tasksToRemove.push(task.id);
                }

                for (let j = 0; j < task.subtasks.length; j++) {
                    const subtask = task.subtasks[j];
                    if(Object.prototype.hasOwnProperty.call(subtask, 'sort_order') && subtask.pendin_complete) {
                        jobpartsToRemove.push(subtask.id);
                    } else if(subtask.pending_completed) {
                        subtasksToRemove.push(subtask.id);
                    }
                }
            }

            this.$axios.post(this.$apiUrl + '/tasklist/completed/remove',{
                current_user: this.currentUserID,
                tasks: tasksToRemove,
                subtasks: subtasksToRemove,
                jobparts: jobpartsToRemove,
            }).then(resp => {
                if(resp.data.success) {
                    this.$emit('get-tasks')
                }
            }).catch(e => {
                console.log(e)
            })
        },
        updateTasks(type) {
            this.$emit('update-tasks', type);
        },
        swapTaskList(id) {
            this.$emit('swap-task-list', id);
        },
        addNewTask() {
            this.newTaskLoading = true;
            this.$axios.post(this.$apiUrl + '/tasklist/tasks',{
                current_user: this.currentUserID,
                title: this.newTaskText,
                note: "",
                position: this.newTaskPositionTop ? "Top" : "Bottom",
                priority: "Normal",
                isPaintTask: this.user.department === 9
            }).then(resp => {
                if(resp.data.success){
                    this.updateTasks('new');
                    this.newTaskLoading = false;
                    this.newTaskText = "";
                } else {
                    console.log(resp)
                }
            }).catch(e => {
                console.log(e)
            });
        },
        reorderSwitchChanged(val) {
            this.$emit('update-can-reorder', val)
        },
        tasklistTypeChanged(e) {
            this.$emit('tasklist-type-changed', e.target.value)
        },
        openTaskslist() {
            window.open("https://tasks.darkside-developments.com/user/" + this.user.id)
        }
    },
    watch: {
        /*tasks: {
            deep: true,
            handler(val) {
                setTimeout(() => {
                    let count = 0;
                    for (let i = 0; i < val.length; i++) {
                        count++;
                        const task = val[i];
                        if (task.pending_completed || task.pendin_complete) {
                            this.noCompletedTasks = false;
                            break;
                        }
                        for (let j = 0; j < task.subtasks.length; j++) {
                            const subtask = task.subtasks[j];
                            if (subtask.pending_completed || subtask.pendin_complete) {
                                this.noCompletedTasks = false;
                                break;
                            }
                        }
                    }

                    if(count === val.length) {
                        this.noCompletedTasks = true;
                    }
                }, 200)
            }
        }*/
    }
}
</script>

<style lang="scss">
/*.tasklist-header {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100% );
        opacity: 0.5;
        pointer-events: none;
        transition-duration: 0.22s;
        transition-property: left, opacity, width;
        transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    }
}*/
</style>
